<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" dense flat>
          <v-toolbar-title>{{region.descript}}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-tabs right>
            <v-tab :to="`/region/${id}`">Region Details</v-tab>
            <v-tab :to="`/region/${id}/outlets`">Outlets</v-tab>
            <v-tab :to="`/region/${id}/sub-regions`">Sub Regions</v-tab>
            <v-tab :to="`/region/${id}/sale-reps`">Sale Reps</v-tab>
          </v-tabs>

          <v-row>
            <v-divider></v-divider>
          </v-row>

          <router-view/>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      region: {}
    };
  },
  watch: {
    "$route.params.id": {
      handler: "getData",
      immediate: true
    }
  },
  methods: {
    getData(val) {
      this.id = val;
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territory/${val}`)
        .then(res => {
          console.log(res, 'region');
          self.region = res;
          self.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {

  }
};
</script>

<style lang="scss" scoped>
</style>